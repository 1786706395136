<script>
import { useAuth } from 'src/lib/auth'
import { info, error, failure, success } from 'src/lib/notify'
import { useLocalStorage } from 'src/lib/storage'
import { Notify } from 'quasar'
import { ref, computed, watch } from 'vue'
import { useRest } from 'src/lib/rest'
import { alterUrl, markdown, markdownClickHandler, go } from 'src/lib/util.js'
import { useRoute, useRouter } from 'vue-router'
import { t } from 'src/lib/i18n'
import ComplexPassword from './ComplexPassword.vue'
import googleLogo from 'assets/super-tiny-icons/google.svg'
import linkedInLogo from 'assets/super-tiny-icons/linkedin.svg'
import PasswordComplexity from './PasswordComplexity.vue'
import SeparatorDiv from './SeparatorDiv.vue'
import EmailInput from './EmailInput.vue'
import { useValidation } from 'src/lib/validation.js'
import { StructuredText } from '../../node_modules/vue-datocms/dist/index.esm.js'
import ButtonPanel from 'src/components/ButtonPanel.vue'

const developing = '' // sign-up, sign-in, email-sign-up, email-code, email-link, complete, set-password, password, forgot-password, forgot-password-code, reset-password, email-sign-up, sign-up-code, callback. Remove before flight.

let submitPromise = {}

export default {
  components: {
    SeparatorDiv,
    ComplexPassword,
    PasswordComplexity,
    EmailInput,
    StructuredText,
    ButtonPanel
  },
  props: {
    view: { type: String, required: true },
    provider: { type: String, default: null },
    defaultCode: { type: String, default: null },
    defaultFirstName: { type: String, default: null },
    defaultLastName: { type: String, default: null },
    defaultEmail: { type: String, default: null },
    redirect: { type: String, default: null },
    signUpTitle: { type: String, default: null },
    signInTitle: { type: String, default: null },
    emailCodeTitle: { type: String, default: null },
    state: { type: String, default: null },
    vertical: { type: Boolean, default: false },
    test: { type: Boolean, default: false },
    animated: { type: Boolean, default: false }
  },
  emits: [
    'update:view',
    'login'
  ],
  setup (props, { emit }) {
    const auth = useAuth()
    const localStorage = useLocalStorage()
    const rest = useRest()
    const route = useRoute()
    const router = useRouter()
    const code = ref(props.defaultCode)
    const email = ref(props.defaultEmail)
    const loading = ref(false)
    const password = ref('')
    const newPassword = ref('')
    const confirmPassword = ref('')
    const passwordComplexity = ref(0)
    const firstName = ref(props.defaultFirstName)
    const lastName = ref(props.defaultLastName)
    const formError = ref('')
    const validation = useValidation()
    const unwatchLogin = watch(
      () => auth.loggedIn,
      loggedIn => {
        if (loggedIn) {
          emit('login', {})
        }
      }
    )
    let preservingError = false
    const preserveError = () => {
      preservingError = true
      setTimeout(() => {
        preservingError = false
      }, 1000)
    }
    // Clear form error when view switches
    watch(
      () => props.view,
      () => {
        if (!preservingError) {
          formError.value = ''
        }
      }
    )

    const origin = ref('')
    const redirectUri = (provider) => origin.value
      ? new URL(`/login/callback/${provider}`, origin.value).toString()
      : ''

    const state = computed(() => {
      const tmp = new URLSearchParams()
      if (props.redirect) {
        tmp.set('redirect', props.redirect)
      }
      return tmp.toString()
    })

    const impersonationKey = computed(() => localStorage.impersonationKey)
    const impersonate = () => {
      password.value = impersonationKey.value
      submitPassword()
      password.value = ''
    }

    const hideFirstAndLast = computed(() => !!props.defaultFirstName && !!props.defaultLastName)

    const providers = computed(() => [
      {
        name: 'google',
        labelKey: 'signInWithGoogle',
        icon: googleLogo,
        url: alterUrl('https://accounts.google.com/o/oauth2/v2/auth', {
          query: {
            client_id: '176211775242-b7a03t858fbo3nf98mj2m4qaf1enipkv.apps.googleusercontent.com',
            redirect_uri: redirectUri('google'),
            response_type: 'code',
            scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid profile',
            state: state.value
          }
        })
      },
      {
        name: 'linkedIn',
        labelKey: 'signInWithLinkedIn',
        icon: linkedInLogo,
        // https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin/context
        // https://docs.microsoft.com/en-us/linkedin/consumer/integrations/self-serve/sign-in-with-linkedin
        url: alterUrl('https://www.linkedin.com/oauth/v2/authorization', {
          query: {
            response_type: 'code',
            client_id: '77y512vzwr7yi8',
            redirect_uri: redirectUri('linkedin'),
            scope: 'r_liteprofile r_emailaddress',
            state: state.value
          }
        })
      }
      // {
      //   name: 'microsoft',
      //   labelKey: 'signInWithMicrosoft',
      //   icon: microsoftLogo,
      //   url: alterUrl('https://login.microsoftonline.com/common/oauth2/v2.0/authorize', {
      //     query: {
      //       response_type: 'code',
      //       client_id: '4343ce27-a3d6-4e8e-bb95-4790e2f9135a',
      //       redirect_uri: redirectUri('microsoft'),
      //       scope: 'https://graph.microsoft.com/user.read'
      //     }
      //   })
      // },
      // {
      //   name: 'facebook',
      //   labelKey: 'signInWithFacebook',
      //   icon: facebookLogo,
      //   // https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow/
      //   url: alterUrl('https://www.facebook.com/v10.0/dialog/oauth', {
      //     query: {
      //       response_type: 'code',
      //       client_id: '761832524315907',
      //       redirect_uri: redirectUri('facebook'),
      //       scope: 'public_profile,email',
      //       state: state.value
      //     }
      //   })
      // }
    ])

    go.slowly(() => {
      origin.value = window.location.origin
    })

    async function call (fn) {
      try {
        loading.value = true
        await fn()
      } catch (err) {
        for (const key in props) {
          console.info(key, props[key])
        }
        formError.value = error(err, {
          source: 'loginForm',
          view: props.view
        })
      } finally {
        loading.value = false
      }
    }

    async function submitEmail () {
      await submit({
        email: email.value
      })
    }

    async function submitEmailSignUp () {
      await call(async () => {
        await rest.post('/rest/auth/sign-up', {
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
          password: password.value,
          redirect: props.redirect
        })
        emit('update:view', 'sign-up-code', {})
      })
    }

    async function submitCode () {
      const success = await submit({
        email: email.value,
        code: code.value
      })
      if (!success && props.view === 'complete') {
        preserveError()
        emit('update:view', 'sign-in', { code: undefined })
      }
    }

    async function submitPassword () {
      await submit({
        email: email.value,
        password: password.value
      })
    }

    async function submitForgotPassword () {
      await call(async () => {
        await rest.post('/rest/auth/forgot-password', {
          email: email.value,
          redirect: props.redirect
        })
        emit('update:view', 'forgot-password-code', {})
      })
    }

    async function submitForgotPasswordCode () {
      await call(async () => {
        const result = await rest.post('/rest/auth/reset-password', {
          email: email.value,
          code: code.value
        })
        if (result.success) {
          emit('update:view', 'reset-password', {})
        } else {
          formError.value = 'The reset code is invalid, or has expired.'
          // preserveError()
          // emit('update:view', 'forgot-password', { code: undefined })
        }
      })
    }

    async function submitSignUp () {
      await call(async () => {
        const result = await rest.post('/rest/auth/sign-up', {
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
          password: password.value,
          redirect: props.redirect
        })
        if (result.success) {
          emit('update:view', 'sign-up-code', {})
        } else {
          formError.value = result.reason
            ? t(result.reason)
            : result.message
          preserveError()
          emit('update:view', 'email-sign-up', { email: undefined })
        }
      })
    }

    async function submitResetPassword () {
      await call(async () => {
        const success = await submit({
          password: newPassword.value,
          code: code.value,
          email: email.value
        }, '/rest/auth/reset-password')
        if (success) {
          password.value = newPassword.value
          info('Your password has been updated!')

          // Do not accept redirects from other origins.
          if (props.redirect?.startsWith('/')) {
            router.push(props.redirect)
          }
        } else {
          failure('We could not reset your password. Please try again.')
        }
      })
    }

    async function submitSignUpCode () {
      await submit({
        email: email.value,
        code: code.value,
        signUp: true
      })
    }

    async function sendNewEmailCode () {
      const res = await rest.post(
        alterUrl('/rest/auth/login', {
          query: {
            timezoneOffset: new Date().getTimezoneOffset()
          }
        }),
        {
          email: email.value,
          resend: true
        }
      )
      if (res.success) {
        emit('update:view', 'email-code', {
          email: email.value
        })
        success('We have sent the email again. Check your inbox.')
      } else {
        emit('update:view', 'sign-in', {})
        info('We could not send the email again. Please try to sign in again.')
      }
    }

    async function sendNewSignUpCode () {
      const res = await rest.post('/rest/auth/sign-up', {
        email: email.value,
        resend: true
      })
      if (res.success) {
        emit('update:view', 'sign-up-code', {
          email: email.value
        })
        success('We have sent the email again. Check your inbox.')
      } else {
        emit('update:view', 'sign-up', {})
        info('We could not send the email again. Please try to sign up again.')
      }
    }

    async function sendNewCode () {
      await call(async () => {
        const res = await rest.post('/rest/auth/forgot-password', {
          email: email.value,
          force: true
        })
        if (res.success) {
          success('We have sent the email again. Check your inbox.')
        } else {
          failure('We could not send the email again. It has been sent too recently.')
        }
      })
    }

    async function alreadyHaveAnAccount () {
      emit('update:view', 'sign-in', {})
    }

    async function dontHaveAnAccount () {
      emit('update:view', 'sign-up', {})
    }

    async function signUpWithEmail () {
      emit('update:view', 'email-sign-up', {})
    }

    async function signInWithEmail () {
      emit('update:view', 'email-sign-in', {})
    }

    /**
     * @param {{email:String, code:String, provider:String, redirect:String, retry:String}} form
     */
    async function submit (form, path = '/rest/auth/login') {
      try {
        loading.value = true
        const retry = parseInt(form.retry || '0')

        // Sleep for the retry
        await new Promise(resolve => setTimeout(resolve, retry * 1000))

        const key = JSON.stringify(form)

        if (!submitPromise[key] || Date.now() - submitPromise.ts > 1000 * 5) {
          submitPromise = {
            ts: Date.now(),
            [key]: rest.post(alterUrl(path, {
              query: {
                timezoneOffset: new Date().getTimezoneOffset()
              }
            }), form)
          }
        }
        const result = await submitPromise[key]

        if (result.location) localStorage.location = result.location

        if (!result.success) {
          if (result.retry && retry < 3) {
            const p = providers.value.find(p => p.name === props.provider)
            const url = new URL(p.url)
            url.searchParams.set('state', new URLSearchParams({
              redirect: form.redirect,
              retry: parseInt(form.retry || '0') + 1
            }).toString())

            console.log('Retrying login with', url.toString())
            window.location = url.toString()
          }
          formError.value = result.message
          // // An error occured...
          // Notify.create({
          //   icon: 'fas fa-triangle-exclamation',
          //   color: 'negative',
          //   message: result.message,
          //   multiLine: true,
          //   actions: [
          //     { label: 'Dismiss', handler: () => {} }
          //   ]
          // })
          return false
        } else if (result.jwt) {
          // We logged in!
          unwatchLogin()
          localStorage.jwt = result.jwt
          localStorage.downloadToken = result.downloadToken
          localStorage.profile = result.profile
          emit('login', result)
          return true
        } else if (result.step) {
          // We're not done yet...
          emit('update:view', result.step, { email: email.value })
          return false
        }
      } catch (err) {
        if (form.code && form.provider) {
          // Provider rejected login
          emit('update:view', 'login', { code: undefined })
        }
        for (const key in props) {
          console.info(key, props[key])
        }
        formError.value = error(err, {
          subject: 'Unexpected error with login form',
          source: 'loginForm.submit',
          view: props.view
        })
        if (props.view === 'callback') {
          preserveError()
          emit('update:view', 'sign-in', { code: undefined })
        }
        return false
      } finally {
        loading.value = false
      }
    }

    const inputStyle = {
      outlined: true
    }

    const query = computed(() => {
      return new URLSearchParams(route.query).toString()
    })
    const loginLink = computed(() => `/login?${query.value}`)
    const signUpLink = computed(() => `/login/sign-up?${query.value}`)

    watch(
      () => props.view,
      (view) => {
        go(() => {
          if (code.value) {
            switch (view) {
              case 'forgot-password-code':
                submitForgotPasswordCode()
                break
              case 'sign-up-code':
                submitSignUpCode()
                break
            }
          }
        })
      }, {
        immediate: true
      }
    )

    go(async () => {
      if (!props.test && props.view === 'callback' && !auth.signingIn) {
        auth.signingIn = true
        try {
          // http://localhost:8080/login/callback?code=AQTfAV6bu5BDbiUsMl0hDJw0cSK-nbAm5LVQ3tlp9JMj5aB4cOEsDzrjmy17Vgs8movLgYRgZkgdka2GpeiPYTAQiSD1HHyzHouPpG1Xa0FLqO9PcW3GcNEVrX-uoOBiwgfBI7Rybp7E-UL870QOyO9Dw9cKQqZ8-xv8L7zB_d3RcgolnGsqYwD8P7M1-g
          // http://localhost:8080/login/callback/linkedin?error=user_cancelled_login&error_description=The+user+cancelled+the+login
          if (route.query.error) {
            Notify.create({
              icon: 'fas fa-triangle-exclamation',
              color: 'negative',
              message: `Could not complete login: ${route.query.error_description || route.query.error}`,
              multiLine: true,
              actions: [
                { label: 'Dismiss', handler: () => { console.log('Dismiss') } }
              ]
            })
            emit('update:view', 'login', {})
          } else {
            const state = new URLSearchParams(props.state)
            submit({
              code: props.defaultCode,
              provider: props.provider,
              redirect: state.get('redirect'),
              retry: state.get('retry') || '0'
            })
          }
        } finally {
          auth.signingIn = false
        }
      }
    })

    go(async () => {
      if (!props.test && (props.view === 'complete' || props.view === 'code')) {
        await submitCode()
      }
    })

    go(() => {
      if (developing) {
        emit('update:view', developing, {})
      }
    })

    const privacyPolicy = ref(null)
    const termsAndConditions = ref(null)

    go(async () => {
      const privacy = await rest.get('/rest/privacy-policy')
      privacyPolicy.value = privacy
      const terms = await rest.get('/rest/terms-and-conditions')
      termsAndConditions.value = terms
    })

    const showModal = (ev, name) => {
      ev.preventDefault()
      ev.stopPropagation()
      switch (name) {
        case 'privacy-policy':
          modalContent.value = privacyPolicy.value
          break
        case 'terms-and-conditions':
          modalContent.value = termsAndConditions.value
          break
      }
      if (!modalContent.value) {
        console.warn(`Content not loaded yet for ${name}`)
        return
      }
      modalShowing.value = true
    }

    const modalShowing = ref(false)
    const modalContent = ref(null)

    return {
      showModal,
      modalShowing,
      modalContent,
      formError,
      developing,
      code,
      email,
      password,
      newPassword,
      confirmPassword,
      passwordComplexity,
      firstName,
      lastName,
      hideFirstAndLast,
      providers,
      loading,
      inputStyle,
      submitEmail,
      submitCode,
      submitPassword,
      submitForgotPassword,
      submitForgotPasswordCode,
      submitSignUp,
      submitSignUpCode,
      submitResetPassword,
      submitEmailSignUp,
      sendNewCode,
      sendNewEmailCode,
      markdown,
      markdownClickHandler,
      alreadyHaveAnAccount,
      dontHaveAnAccount,
      signUpWithEmail,
      signInWithEmail,
      sendNewSignUpCode,
      query,
      loginLink,
      signUpLink,
      impersonationKey,
      impersonate,
      t,
      validation
    }
  }
}
</script>

<template>
  <div class="LoginForm relative-position" :class="{ vertical }">
    <div v-if="developing" style="color: red; position: fixed; top: 0; left: 0; width: 100vw; text-align: center; font-size: 24px;">
      Developing {{ developing.toUpperCase() }}. Remove before flight! Current: <code>{{ view }}</code>
    </div>
    <q-tab-panels :animated="animated" :model-value="view"
                  :transition-next="animated ? 'slide-left' : null"
                  :transition-prev="animated ? 'slide-left' : null"
                  infinite
                  class="bg-transparent text-left"
    >
      <!--
         d8b
         Y8P

.d8888b  888  .d88b.  88888b.         888  888 88888b.
88K      888 d88P"88b 888 "88b        888  888 888 "88b
"Y8888b. 888 888  888 888  888 888888 888  888 888  888
     X88 888 Y88b 888 888  888        Y88b 888 888 d88P
 88888P' 888  "Y88888 888  888         "Y88888 88888P"
                  888                          888
             Y8b d88P                          888
              "Y88P"                           888
-->
      <q-tab-panel name="sign-up">
        <div class="text-h6">
          {{ signUpTitle || t('loginForm.signUp.title') }}
        </div>
        <p>{{ t('loginForm.signUp.caption') }}</p>
        <div class="form-container q-gutter-sm">
          <q-btn
            v-for="(p, i) of providers"
            :key="i"
            :href="p.url" outline
            type="a" no-caps
            class="full-width text-shade-3"
            data-info="sign-up"
          >
            <q-img :src="p.icon" width="24px" height="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signUp.${p.labelKey}`) }}
            </div>
          </q-btn>
          <q-btn outline no-caps class="full-width text-shade-3" @click="signUpWithEmail" data-info="sign-up-with-email">
            <q-icon name="fas fa-envelope" class="text-shade-5" size="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signUp.signInWithEmail`) }}
            </div>
          </q-btn>
          <q-separator class="q-my-lg" />
          <q-no-ssr><div v-html="markdown(t('loginForm.emailSignUp.alreadyHaveAnAccount', { link: markdownClickHandler('alreadyHaveAnAccount') }))" /></q-no-ssr>
        </div>
      </q-tab-panel>
      <!--
         d8b                          d8b
         Y8P                          Y8P

.d8888b  888  .d88b.  88888b.         888 88888b.
88K      888 d88P"88b 888 "88b        888 888 "88b
"Y8888b. 888 888  888 888  888 888888 888 888  888
     X88 888 Y88b 888 888  888        888 888  888
 88888P' 888  "Y88888 888  888        888 888  888
                  888
             Y8b d88P
              "Y88P"
-->
      <q-tab-panel name="sign-in" class="q-gutter-sm">
        <div class="text-h6">
          {{ signInTitle || t('loginForm.signIn.title') }}
        </div>
        <div class="form-container q-gutter-sm">
          <q-btn
            v-for="(p, i) of providers"
            :key="i"
            :href="p.url" outline
            type="a" no-caps
            class="full-width text-shade-3"
            :data-info="`sign-in-with-${p.labelKey}`"
          >
            <q-img :src="p.icon" width="24px" height="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signIn.${p.labelKey}`) }}
            </div>
          </q-btn>
          <q-btn outline no-caps class="full-width text-shade-3" @click="signInWithEmail" data-info="sign-in-with-email">
            <q-icon name="fas fa-envelope" class="text-shade-5" size="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signIn.signInWithEmail`) }}
            </div>
          </q-btn>
          <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
          <q-separator class="q-my-lg" />
          <slot name="sign-in-footer">
            <q-no-ssr><div v-html="markdown(t('loginForm.signIn.dontHaveAnAccount', { link: markdownClickHandler('dontHaveAnAccount') }))" /></q-no-ssr>
          </slot>
        </div>
      </q-tab-panel>
      <!--
                                d8b 888                 d8b                          d8b
                                Y8P 888                 Y8P                          Y8P
                                    888
 .d88b.  88888b.d88b.   8888b.  888 888        .d8888b  888  .d88b.  88888b.         888 88888b.
d8P  Y8b 888 "888 "88b     "88b 888 888        88K      888 d88P"88b 888 "88b        888 888 "88b
88888888 888  888  888 .d888888 888 888 888888 "Y8888b. 888 888  888 888  888 888888 888 888  888
Y8b.     888  888  888 888  888 888 888             X88 888 Y88b 888 888  888        888 888  888
 "Y8888  888  888  888 "Y888888 888 888         88888P' 888  "Y88888 888  888        888 888  888
                                                                 888
                                                            Y8b d88P
                                                             "Y88P"
-->
      <q-tab-panel name="email-sign-in" class="q-gutter-sm">
        <div class="text-h6">
          {{ signInTitle || t('loginForm.signIn.title') }}
        </div>
        <div class="form-container q-gutter-sm">
          <q-form @submit="submitEmail">
            <EmailInput type="email"
                        autocomplete="username"
                        v-model="email"
                        v-bind="inputStyle"
                        :autofocus="!test"
                        :label="t('loginForm.signIn.emailAddress')"
                        :rules="[
                          x => !!x || t('loginForm.signIn.emailRequired')
                        ]"
                        lazy-rules="ondemand"
            />
            <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" :label="t('loginForm.signIn.next')"
                   no-caps type="submit"
                   data-info="submit-sign-in"
            />
          </q-form>
          <SeparatorDiv>
            {{ t('loginForm.signIn.or') }}
          </SeparatorDiv>
          <q-btn
            v-for="(p, i) of providers"
            :key="i"
            :href="p.url" outline
            type="a" no-caps
            class="full-width text-shade-3"
            :data-info="`sign-in-with-${p.labelKey}`"
          >
            <q-img :src="p.icon" width="24px" height="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signIn.${p.labelKey}`) }}
            </div>
          </q-btn>
          <q-separator class="q-my-lg" />
          <q-no-ssr><div v-html="markdown(t('loginForm.signIn.dontHaveAnAccount', { link: markdownClickHandler('dontHaveAnAccount') }))" /></q-no-ssr>
        </div>
      </q-tab-panel>
      <!--
                                d8b 888                               888
                                Y8P 888                               888
                                    888                               888
 .d88b.  88888b.d88b.   8888b.  888 888         .d8888b  .d88b.   .d88888  .d88b.
d8P  Y8b 888 "888 "88b     "88b 888 888        d88P"    d88""88b d88" 888 d8P  Y8b
88888888 888  888  888 .d888888 888 888 888888 888      888  888 888  888 88888888
Y8b.     888  888  888 888  888 888 888        Y88b.    Y88..88P Y88b 888 Y8b.
 "Y8888  888  888  888 "Y888888 888 888         "Y8888P  "Y88P"   "Y88888  "Y8888
-->
      <q-tab-panel name="email-code" class="q-gutter-sm">
        <div class="text-h6">
          {{ emailCodeTitle || t('loginForm.emailCode.title') }}
        </div>
        <p>{{ t('loginForm.emailCode.checkEmailForCode') }}</p>
        <div class="form-container">
          <q-form @submit="submitCode" data-info="submit-code-form">
            <q-input v-bind="inputStyle"
                     clearable
                     v-model="code"
                     type="code"
                     :label="t('loginForm.emailCode.confirmationCode')"
                     :autofocus="!test"
                     lazy-rules="ondemand"
                     :rules="[
                       val => !!val || t('loginForm.emailCode.confirmationCodeRequired')
                     ]"
                     data-info="code"
            />
            <q-no-ssr>
              <div class="q-mt-sm" v-html="markdown(t('loginForm.emailCode.sendNewCode', {
                link: markdownClickHandler('sendNewEmailCode')
              }))"
              />
            </q-no-ssr>
            <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" :label="t('loginForm.emailCode.next')"
                   no-caps type="submit"
                   data-info="submit-code"
            />
            <q-separator class="q-my-md" />
            <div class="q-gutter-sm">
              <div class="text-h6">
                {{ t('loginForm.emailCode.skipVerification') }}
              </div>
              <div>{{ t('loginForm.emailCode.skipVerificationCaption') }}</div>
              <q-btn
                v-for="(p, i) of providers"
                :key="i"
                :href="p.url" outline
                type="a" no-caps
                class="full-width text-shade-3"
                :data-info="`sign-up-with-${p.labelKey}`"
              >
                <q-img :src="p.icon" width="24px" height="24px" />
                <div class="q-space text-light-0 text-left q-ml-md">
                  {{ t(`loginForm.signUp.${p.labelKey}`) }}
                </div>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-tab-panel>
      <!--
                                d8b 888        888 d8b          888
                                Y8P 888        888 Y8P          888
                                    888        888              888
 .d88b.  88888b.d88b.   8888b.  888 888        888 888 88888b.  888  888
d8P  Y8b 888 "888 "88b     "88b 888 888        888 888 888 "88b 888 .88P
88888888 888  888  888 .d888888 888 888 888888 888 888 888  888 888888K
Y8b.     888  888  888 888  888 888 888        888 888 888  888 888 "88b
 "Y8888  888  888  888 "Y888888 888 888        888 888 888  888 888  888
-->
        <q-tab-panel name="email-link" class="q-gutter-sm">
          <div class="text-h6">
          {{ t('loginForm.emailLink.title') }}
        </div>
        <p>{{ t('loginForm.emailLink.checkEmailForLink') }}</p>
        <q-no-ssr>
          <div class="q-mt-sm" v-html="markdown(t('loginForm.emailCode.sendNewCode', {
            link: markdownClickHandler('sendNewSignUpCode')
          }))"
          />
        </q-no-ssr>
        <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
        <q-separator class="q-my-md" />
        <div class="q-gutter-sm">
          <div class="text-h6">
            {{ t('loginForm.emailCode.skipVerification') }}
          </div>
          <div>{{ t('loginForm.emailCode.skipVerificationCaption') }}</div>
          <q-btn
            v-for="(p, i) of providers"
            :key="i"
            :href="p.url" outline
            type="a" no-caps
            class="full-width text-shade-3"
            :data-info="`sign-up-with-${p.labelKey}`"
          >
            <q-img :src="p.icon" width="24px" height="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signUp.${p.labelKey}`) }}
            </div>
          </q-btn>
        </div>
      </q-tab-panel>

      <!--
                                         888          888
                                         888          888
                                         888          888
 .d8888b  .d88b.  88888b.d88b.  88888b.  888  .d88b.  888888  .d88b.
d88P"    d88""88b 888 "888 "88b 888 "88b 888 d8P  Y8b 888    d8P  Y8b
888      888  888 888  888  888 888  888 888 88888888 888    88888888
Y88b.    Y88..88P 888  888  888 888 d88P 888 Y8b.     Y88b.  Y8b.
 "Y8888P  "Y88P"  888  888  888 88888P"  888  "Y8888   "Y888  "Y8888
                                888
                                888
                                888
-->
      <q-tab-panel name="complete" class="q-gutter-sm">
        <div class="text-h6">
          {{ t('loginForm.complete.title') }}
        </div>
        <div class="text-center text-h5">
          {{ t('loginForm.complete.completing') }}
        </div>
      </q-tab-panel>
      <!--
                  888                                                                                   888
                  888                                                                                   888
                  888                                                                                   888
.d8888b   .d88b.  888888        88888b.   8888b.  .d8888b  .d8888b  888  888  888  .d88b.  888d888  .d88888
88K      d8P  Y8b 888           888 "88b     "88b 88K      88K      888  888  888 d88""88b 888P"   d88" 888
"Y8888b. 88888888 888    888888 888  888 .d888888 "Y8888b. "Y8888b. 888  888  888 888  888 888     888  888
     X88 Y8b.     Y88b.         888 d88P 888  888      X88      X88 Y88b 888 d88P Y88..88P 888     Y88b 888
 88888P'  "Y8888   "Y888        88888P"  "Y888888  88888P'  88888P'  "Y8888888P"   "Y88P"  888      "Y88888
                                888
                                888
                                888
-->
      <q-tab-panel name="set-password" class="q-gutter-sm">
        <div class="text-h6">
          {{ t('loginForm.setPassword.title') }}
        </div>
      </q-tab-panel>
      <!--
                                                                        888
                                                                        888
                                                                        888
88888b.   8888b.  .d8888b  .d8888b  888  888  888  .d88b.  888d888  .d88888
888 "88b     "88b 88K      88K      888  888  888 d88""88b 888P"   d88" 888
888  888 .d888888 "Y8888b. "Y8888b. 888  888  888 888  888 888     888  888
888 d88P 888  888      X88      X88 Y88b 888 d88P Y88..88P 888     Y88b 888
88888P"  "Y888888  88888P'  88888P'  "Y8888888P"   "Y88P"  888      "Y88888
888
888
888
-->
      <q-tab-panel name="password" class="q-gutter-sm">
        <div class="text-h6">
          {{ t('loginForm.password.title') }}
        </div>
        <div class="form-container q-gutter-sm">
          <q-form @submit="submitPassword" data-info="submit-password-form">
            <div class="text-center q-ma-lg text-bold">
              {{ email }}
            </div>
            <input v-if="email" type="email"
                   autocomplete="username" :value="email"
                   style="display: none;"
                   data-info="email"
            >
            <q-input clearable
                     type="password"
                     v-bind="inputStyle"
                     v-model="password"
                     autocomplete="password"
                     :label="t('loginForm.password.password')"
                     :autofocus="!test"
                     lazy-rules="ondemand"
                     :rules="[
                       val => !!val || t('loginForm.password.passwordRequired')
                     ]"
                     data-info="password"
            />
            <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" :label="t('loginForm.password.next')"
                   no-caps type="submit"
                   data-info="submit-password"
            />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" label="Impersonate"
                   @click="impersonate"
                   no-caps type="button"
                   v-if="impersonationKey"
                   data-info="impersonate"
            />
          </q-form>
          <div>
            <router-link
              :to="{
                path: '/login/forgot-password',
                query: {
                  email,
                  redirect
                }
              }"
              class="link"
            >
              {{ t('loginForm.password.forgotPassword') }}
            </router-link>
          </div>
        </div>
      </q-tab-panel>
      <!--
 .d888                                    888                                                                                   888
d88P"                                     888                                                                                   888
888                                       888                                                                                   888
888888  .d88b.  888d888  .d88b.   .d88b.  888888        88888b.   8888b.  .d8888b  .d8888b  888  888  888  .d88b.  888d888  .d88888
888    d88""88b 888P"   d88P"88b d88""88b 888           888 "88b     "88b 88K      88K      888  888  888 d88""88b 888P"   d88" 888
888    888  888 888     888  888 888  888 888    888888 888  888 .d888888 "Y8888b. "Y8888b. 888  888  888 888  888 888     888  888
888    Y88..88P 888     Y88b 888 Y88..88P Y88b.         888 d88P 888  888      X88      X88 Y88b 888 d88P Y88..88P 888     Y88b 888
888     "Y88P"  888      "Y88888  "Y88P"   "Y888        88888P"  "Y888888  88888P'  88888P'  "Y8888888P"   "Y88P"  888      "Y88888
                             888                        888
                        Y8b d88P                        888
                         "Y88P"                         888
-->
      <q-tab-panel name="forgot-password">
        <div class="text-h6">
          {{ t('loginForm.forgotPassword.title') }}
        </div>
        <p>{{ t('loginForm.forgotPassword.enterEmailToResetPassword') }}</p>
        <div class="form-container">
          <q-form @submit="submitForgotPassword" data-info="forgot-password-form">
            <EmailInput type="email"
                        v-model="email"
                        v-bind="inputStyle"
                        :label="t('loginForm.forgotPassword.emailAddress')"
                        :rules="[
                          x => !!x || t('loginForm.forgotPassword.emailRequired')
                        ]"
                        lazy-rules="ondemand"
                        autocomplete="username"
                        data-info="email"
            />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" :label="t('loginForm.forgotPassword.resetPassword')"
                   no-caps type="submit"
                   data-info="forget-password-submit"
            />
          </q-form>
        </div>
      </q-tab-panel>
      <!--
 .d888                                    888                                                                                   888                               888
d88P"                                     888                                                                                   888                               888
888                                       888                                                                                   888                               888
888888  .d88b.  888d888  .d88b.   .d88b.  888888        88888b.   8888b.  .d8888b  .d8888b  888  888  888  .d88b.  888d888  .d88888         .d8888b  .d88b.   .d88888  .d88b.
888    d88""88b 888P"   d88P"88b d88""88b 888           888 "88b     "88b 88K      88K      888  888  888 d88""88b 888P"   d88" 888        d88P"    d88""88b d88" 888 d8P  Y8b
888    888  888 888     888  888 888  888 888    888888 888  888 .d888888 "Y8888b. "Y8888b. 888  888  888 888  888 888     888  888 888888 888      888  888 888  888 88888888
888    Y88..88P 888     Y88b 888 Y88..88P Y88b.         888 d88P 888  888      X88      X88 Y88b 888 d88P Y88..88P 888     Y88b 888        Y88b.    Y88..88P Y88b 888 Y8b.
888     "Y88P"  888      "Y88888  "Y88P"   "Y888        88888P"  "Y888888  88888P'  88888P'  "Y8888888P"   "Y88P"  888      "Y88888         "Y8888P  "Y88P"   "Y88888  "Y8888
                             888                        888
                        Y8b d88P                        888
                         "Y88P"                         888
-->
      <q-tab-panel name="forgot-password-code">
        <div class="text-h6">
          {{ t('loginForm.forgotPasswordCode.title') }}
        </div>
        <p>{{ t('loginForm.forgotPasswordCode.checkEmailForCode') }}</p>
        <div class="form-container">
          <q-form @submit="submitForgotPasswordCode" data-info="forgot-password-code-form">
            <q-input v-model="code" v-bind="inputStyle"
                     :label="t('loginForm.forgotPasswordCode.code')"
                     :rules="[
                       x => !!x || t('loginForm.forgotPasswordCode.codeRequired')
                     ]"
                     lazy-rules="ondemand"
                     data-info="forgot-password-code"
            />
            <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" :label="t('loginForm.forgotPasswordCode.next')"
                   no-caps type="submit"
                   data-info="forgot-password-code-submit"
            />
          </q-form>
          <q-no-ssr>
            <div class="q-mt-sm" v-html="markdown(t('loginForm.forgotPasswordCode.sendNewCode', {
              link: markdownClickHandler('sendNewCode')
            }))"
            />
          </q-no-ssr>
        </div>
      </q-tab-panel>
      <!--
                                   888                                                                                   888
                                   888                                                                                   888
                                   888                                                                                   888
888d888  .d88b.  .d8888b   .d88b.  888888        88888b.   8888b.  .d8888b  .d8888b  888  888  888  .d88b.  888d888  .d88888
888P"   d8P  Y8b 88K      d8P  Y8b 888           888 "88b     "88b 88K      88K      888  888  888 d88""88b 888P"   d88" 888
888     88888888 "Y8888b. 88888888 888    888888 888  888 .d888888 "Y8888b. "Y8888b. 888  888  888 888  888 888     888  888
888     Y8b.          X88 Y8b.     Y88b.         888 d88P 888  888      X88      X88 Y88b 888 d88P Y88..88P 888     Y88b 888
888      "Y8888   88888P'  "Y8888   "Y888        88888P"  "Y888888  88888P'  88888P'  "Y8888888P"   "Y88P"  888      "Y88888
                                                 888
                                                 888
                                                 888
-->
      <q-tab-panel name="reset-password">
        <div class="text-h6">
          {{ t('loginForm.resetPassword.title') }}
        </div>
        <div class="row">
          <div class="form-container">
            <q-form @submit="submitResetPassword" data-info="reset-password-form">
              <input v-if="email" type="email"
                     autocomplete="username" :value="email"
                     style="display: none;"
                     data-info="email"
              >
              <PasswordComplexity class="is-vertical" v-model="newPassword" v-model:complexity="passwordComplexity" data-info="password"/>
              <ComplexPassword
                autocomplete="new-password"
                v-model="newPassword"
                v-model:complexity="passwordComplexity"
                v-bind="inputStyle"
                :label="t('passwordSettings.createAPassword')"
                :rules="[
                  x => !!newPassword || t('passwordSettings.newPasswordRequired'),
                  x => passwordComplexity >= 3 || t('passwordSettings.newPasswordNotComplex')
                ]"
                lazy-rules="ondemand"
              />
              <!-- <q-input type="password"
                       v-model="confirmPassword"
                       v-bind="inputStyle"
                       :label="t('passwordSettings.confirmPassword')"
                       :rules="[
                         x => confirmPassword === newPassword || t('passwordSettings.passwordsDoNotMatch')
                       ]"
                       lazy-rules="ondemand"
              /> -->
              <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
              <q-btn type="submit" color="primary"
                     class="full-width"
                     size="lg"
                     no-caps
                     :label="t('passwordSettings.changePassword')"
                     data-info="reset-password-submit"
              />
            </q-form>
          </div>
          <div class="col-auto q-pl-xl">
            <PasswordComplexity class="not-vertical" v-model="newPassword" v-model:complexity="passwordComplexity" />
          </div>
        </div>
      </q-tab-panel>
      <!--
                                d8b 888                 d8b
                                Y8P 888                 Y8P
                                    888
 .d88b.  88888b.d88b.   8888b.  888 888        .d8888b  888  .d88b.  88888b.         888  888 88888b.
d8P  Y8b 888 "888 "88b     "88b 888 888        88K      888 d88P"88b 888 "88b        888  888 888 "88b
88888888 888  888  888 .d888888 888 888 888888 "Y8888b. 888 888  888 888  888 888888 888  888 888  888
Y8b.     888  888  888 888  888 888 888             X88 888 Y88b 888 888  888        Y88b 888 888 d88P
 "Y8888  888  888  888 "Y888888 888 888         88888P' 888  "Y88888 888  888         "Y88888 88888P"
                                                                 888                          888
                                                            Y8b d88P                          888
                                                             "Y88P"                           888
-->
      <q-tab-panel name="email-sign-up" class="q-gutter-sm">
        <div class="text-h6">
          {{ signUpTitle || t('loginForm.emailSignUp.title') }}
        </div>
        <p>{{ t('loginForm.emailSignUp.caption') }}</p>
        <q-form @submit="submitSignUp" class="row q-col-gutter-sm" data-info="sign-up-form">
          <div class="form-container">
            <div v-if="defaultEmail" class="text-center q-ma-lg text-bold">
              {{ email }}
              <input v-if="email" type="email"
                     autocomplete="username" :value="email"
                     style="display: none;"
              >
            </div>

            <EmailInput v-if="!defaultEmail"
                        type="email"
                        autocomplete="username"
                        v-model="email"
                        v-bind="inputStyle"
                        :autofocus="!test"
                        :label="t('loginForm.emailSignUp.emailAddress')"
                        :rules="[
                          x => !!x || t('loginForm.emailSignUp.emailRequired'),
                          validation.email
                        ]"
                        lazy-rules="ondemand"
                        data-info="email"
            />

            <q-input
              v-if="!hideFirstAndLast"
              type="text"
              autocomplete="given-name"
              v-model="firstName"
              v-bind="inputStyle"
              :label="t('loginForm.emailSignUp.firstName')"
              :rules="[
                x => !!x || t('loginForm.emailSignUp.firstNameRequired')
              ]"
              lazy-rules="ondemand"
              data-info="first-name"
            />
            <q-input
              v-if="!hideFirstAndLast"
              type="text"
              autocomplete="family-name"
              v-model="lastName"
              v-bind="inputStyle"
              :label="t('loginForm.emailSignUp.lastName')"
              :rules="[
                x => !!x || t('loginForm.emailSignUp.lastNameRequired')
              ]"
              lazy-rules="ondemand"
              data-info="last-name"
            />

            <PasswordComplexity class="is-vertical" v-model="password" v-model:complexity="passwordComplexity" data-info="password"/>

            <ComplexPassword
              v-model="password"
              v-model:complexity="passwordComplexity"
              v-bind="inputStyle"
              :label="t('passwordSettings.createAPassword')"
              :rules="[
                x => !!password || t('passwordSettings.newPasswordRequired'),
                x => passwordComplexity >= 3 || t('passwordSettings.newPasswordNotComplex')
              ]"
              lazy-rules="ondemand"
            />

            <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
            <q-dialog v-model="modalShowing" full-height>
              <q-card class="modal">
                <q-btn
                  round
                  flat size="lg"
                  class="dismiss-button z-top"
                  @click="modalShowing = false" icon="fas fa-xmark"
                />
                <q-card-section>
                  <div class="legal">
                    <h2 class="q-px-sm">
                      {{ modalContent.title }}
                    </h2>
                    <h3>
                      {{ modalContent.caption }}
                    </h3>
                    <div class="inline-block bg-shade-2 q-pa-sm q-mb-md text-shade-6">
                      Last updated: {{ modalContent.date }}
                    </div>
                    <StructuredText class="structured-text q-mt-md" :data="modalContent.content" />
                  </div>
                  <ButtonPanel class="text-center q-mt-md">
                    <q-btn
                      label="Close"
                      outline
                      color="primary"
                      v-close-popup
                      no-caps
                    />
                  </ButtonPanel>
                </q-card-section>
              </q-card>
            </q-dialog>
            <q-btn size="lg" no-caps
                   color="primary" type="submit"
                   class="full-width"
                   :label="t('loginForm.emailSignUp.signUp')"
                   data-info="sign-up-submit"
            />
            <q-no-ssr><div class="q-pt-lg" v-html="markdown(t('loginForm.emailSignUp.alreadyHaveAnAccount', { link: markdownClickHandler('alreadyHaveAnAccount') }))" /></q-no-ssr>
          </div>
          <div class="col-12 col-md-auto q-pl-xl not-vertical">
            <div style="height: 153px" />
            <PasswordComplexity v-model="password" v-model:complexity="passwordComplexity" />
          </div>
        </q-form>
      </q-tab-panel>
      <!--
         d8b                                                                          888
         Y8P                                                                          888
                                                                                      888
.d8888b  888  .d88b.  88888b.         888  888 88888b.          .d8888b  .d88b.   .d88888  .d88b.
88K      888 d88P"88b 888 "88b        888  888 888 "88b        d88P"    d88""88b d88" 888 d8P  Y8b
"Y8888b. 888 888  888 888  888 888888 888  888 888  888 888888 888      888  888 888  888 88888888
     X88 888 Y88b 888 888  888        Y88b 888 888 d88P        Y88b.    Y88..88P Y88b 888 Y8b.
 88888P' 888  "Y88888 888  888         "Y88888 88888P"          "Y8888P  "Y88P"   "Y88888  "Y8888
                  888                          888
             Y8b d88P                          888
              "Y88P"                           888
-->
      <q-tab-panel name="sign-up-code">
        <div class="text-h6">
          {{ t('loginForm.emailSignUpCode.title') }}
        </div>
        <div v-if="defaultEmail" class="text-center q-ma-lg text-bold">
          {{ email }}
        </div>
        <p>{{ t('loginForm.emailSignUpCode.caption') }}</p>
        <div class="form-container">
          <q-form @submit="submitSignUpCode" data-info="submit-sign-up-code-form">
            <q-input v-model="code" v-bind="inputStyle"
                     :label="t('loginForm.emailSignUpCode.code')"
                     :rules="[
                       x => !!x || t('loginForm.emailSignUpCode.codeRequired')
                     ]"
                     lazy-rules="ondemand"
                     data-info="code"
            />
            <q-no-ssr>
              <div class="q-mt-sm" v-html="markdown(t('loginForm.emailSignUpCode.sendNewCode', {
                link: markdownClickHandler('sendNewSignUpCode')
              }))"
              />
            </q-no-ssr>

            <div v-if="formError" class="text-negative form-error q-py-md text-center" v-html="markdown(formError)" />
            <q-btn class="q-mt-xs full-width" color="primary"
                   size="lg" :label="t('loginForm.emailSignUpCode.next')"
                   no-caps type="submit"
                   data-info="sign-up-code-submit"
            />
          </q-form>
        </div>
        <q-separator class="q-my-md" />
        <div class="q-gutter-sm">
          <div class="text-h6">
            {{ t('loginForm.emailCode.skipVerification') }}
          </div>
          <div>{{ t('loginForm.emailCode.skipVerificationCaption') }}</div>
          <q-btn
            v-for="(p, i) of providers"
            :key="i"
            :href="p.url" outline
            type="a" no-caps
            class="full-width text-shade-3"
            :data-info="`sign-up-with-${p.labelKey}`"
          >
            <q-img :src="p.icon" width="24px" height="24px" />
            <div class="q-space text-light-0 text-left q-ml-md">
              {{ t(`loginForm.signUp.${p.labelKey}`) }}
            </div>
          </q-btn>
        </div>
      </q-tab-panel>
      <!--
                  888 888 888                        888
                  888 888 888                        888
                  888 888 888                        888
 .d8888b  8888b.  888 888 88888b.   8888b.   .d8888b 888  888
d88P"        "88b 888 888 888 "88b     "88b d88P"    888 .88P
888      .d888888 888 888 888  888 .d888888 888      888888K
Y88b.    888  888 888 888 888 d88P 888  888 Y88b.    888 "88b
 "Y8888P "Y888888 888 888 88888P"  "Y888888  "Y8888P 888  888
-->
      <q-tab-panel name="callback">
        <div class="text-h6">
          {{ t('loginForm.callback.completing') }}
        </div>
      </q-tab-panel>
    </q-tab-panels>
    <q-inner-loading :showing="loading" />
  </div>
</template>

<style lang="scss" scoped>
  .LoginForm {
    &:not(.vertical) {
      @media (max-width: $breakpoint-sm-max) {
        .not-vertical {
          display: none;
        }
        .form-container {
          max-width: 100%;
          width: 100%;
        }
      }
      @media (min-width: $breakpoint-md-min) {
        .is-vertical {
          display: none;
        }
        .form-container {
          max-width: 100%;
          width: 430px;
        }
      }
    }

    &.vertical {
      .not-vertical {
        display: none;
      }
      .form-container {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .modal {
    width: var(--container-width);
    max-width: var(--container-width);
  }

  .legal {
    ::v-deep(h2) {
      font-size: 30px;
    }
    ::v-deep(h3) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }

  .dismiss-button {
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
