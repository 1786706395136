<script>
export default {
  props: {
    vertical: Boolean
  }
}
</script>
<template>
  <div class="SeparatorDiv flex items-center" :class="{vertical, horizontal: !vertical, column: vertical}">
    <div class="line bg-shade-4 q-space" />
    <div><slot /></div>
    <div class="line bg-shade-4 q-space" />
  </div>
</template>
<style lang="scss" scoped>
  .SeparatorDiv {
    gap: 20px;
  }
  .vertical .line {
    width: 1px;
  }
  .horizontal .line {
    height: 1px;
  }
</style>
